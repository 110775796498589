pre {
  overflow-x: auto; }

.form-control, .button, .app-sidebar__button, .form--signin {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); }

@import-normalize;
html {
  box-sizing: border-box; }

*, *::after, *::before {
  box-sizing: inherit; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #848484;
  text-align: left;
  background-color: #ffffff;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

a {
  color: inherit; }
  a:hover {
    text-decoration: none; }

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  margin-bottom: .5rem; }

.control-actions {
  display: flex;
  flex-shrink: 0;
  align-self: stretch;
  align-items: center; }
  .control-actions__seperator {
    align-self: stretch;
    background-color: currentColor;
    margin-bottom: 8px;
    margin-top: 8px;
    width: 1px; }
  .control-actions .button, .control-actions .app-sidebar__button {
    color: currentColor;
    padding: 8px; }
    .control-actions .button .button__text, .control-actions .app-sidebar__button .button__text {
      color: currentColor;
      display: none; }
    .control-actions .button .button__icon, .control-actions .app-sidebar__button .button__icon {
      color: currentColor;
      max-width: 1rem;
      font-size: 1rem; }
    .control-actions .button::after, .control-actions .app-sidebar__button::after {
      align-self: stretch;
      background-color: currentColor;
      margin-bottom: 8px;
      margin-top: 8px;
      width: 1px; }

ul.nav {
  margin: 0;
  padding: 0;
  list-style: none; }

.nav {
  display: flex; }
  .nav.app__nav {
    padding: 0 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem; }
  .nav__item {
    display: flex;
    padding-left: 1rem;
    color: inherit; }
    .nav__item.active {
      color: inherit; }
  .nav__link {
    display: block;
    color: inherit; }

ul.list {
  margin: 0;
  padding: 0;
  list-style: none; }

.list-item {
  display: flex;
  padding: .3rem .5rem;
  color: #4c4c4c; }
  .list-item .button, .list-item .app-sidebar__button {
    color: inherit; }
  .list-item > .list {
    margin-left: 2rem; }
  .list-item--with-root {
    display: block; }
  .list-item__root {
    display: flex; }
  .list-item__fields {
    flex-grow: 1;
    flex-shrink: 0;
    display: flex;
    align-items: center; }
  .list-item__controls {
    display: flex;
    flex-shrink: 0;
    align-self: stretch;
    align-items: center; }
    .list-item__controls-seperator {
      align-self: stretch;
      background-color: currentColor;
      margin-bottom: 8px;
      margin-top: 8px;
      width: 1px; }
    .list-item__controls .button__text {
      display: none; }

input.form-control__input {
  background-color: transparent;
  padding: .2rem .5rem;
  border: 1px solid transparent;
  outline: none; }

.form-control {
  position: relative;
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #6e6e6e;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .form-control.form-control--checkbox {
    border: 1px solid transparent;
    background: transparent;
    border-radius: 0; }

textarea.form-control {
  resize: vertical; }

.form-group__container {
  position: relative;
  display: block;
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057; }
  .form-group__container .react-select__control {
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #6e6e6e;
    border-radius: 0.25rem; }
  .form-group__container .react-select__value-container {
    padding: 0 0.75rem; }

.form-control__input {
  flex: 1 1 auto; }

.form-control--search .form-control__button .button__text,
.form-control--password .form-control__button .button__text {
  display: none; }

.form-control__placeholder {
  pointer-events: none;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2.2rem; }

.form-control__input.form-control__input--has-value + .form-control__placeholder,
.form-control__input:focus + .form-control__placeholder {
  display: none; }

.form-group {
  margin-top: 2rem;
  margin-bottom: 0.5rem;
  display: flex; }
  .form-group .form-label {
    width: 20%; }
  .form-group .form-control, .form-group .form-group__container {
    width: 1%;
    flex: 1 1 auto;
    margin-right: 10%; }
  .form-group + .form-group {
    margin-top: 0.5rem; }

.table {
  width: 100%; }

.button, .app-sidebar__button {
  display: inline-block;
  font-weight: 400;
  color: #848484;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  .button:hover, .app-sidebar__button:hover {
    color: #848484;
    text-decoration: none; }
  .button:focus, .app-sidebar__button:focus, .button.focus, .focus.app-sidebar__button {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(132, 132, 132, 0.25); }
  .button.disabled, .disabled.app-sidebar__button, .button:disabled, .app-sidebar__button:disabled {
    opacity: 0.65;
    box-shadow: none; }
  .button:not(:disabled):not(.disabled):active, .app-sidebar__button:not(:disabled):not(.disabled):active, .button:not(:disabled):not(.disabled).active, .app-sidebar__button:not(:disabled):not(.disabled).active {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
    .button:not(:disabled):not(.disabled):active:focus, .app-sidebar__button:not(:disabled):not(.disabled):active:focus, .button:not(:disabled):not(.disabled).active:focus, .app-sidebar__button:not(:disabled):not(.disabled).active:focus {
      box-shadow: 0 0 0 0.2rem rgba(132, 132, 132, 0.25), inset 0 3px 5px rgba(0, 0, 0, 0.125); }
  .button .button__icon + .button__text, .app-sidebar__button .button__icon + .button__text {
    margin-left: 0.5rem; }
  .button .button__text + .button__icon, .app-sidebar__button .button__text + .button__icon {
    margin-left: 0.5rem; }

a.button.disabled, a.disabled.app-sidebar__button,
fieldset:disabled a.button,
fieldset:disabled a.app-sidebar__button {
  pointer-events: none; }

.button--link {
  font-weight: 400;
  color: #848484;
  text-decoration: none;
  box-shadow: none; }
  .button--link:hover {
    color: #5e5e5e;
    text-decoration: underline; }
  .button--link:focus, .button--link.focus {
    text-decoration: underline;
    box-shadow: none; }
  .button--link:disabled, .button--link.disabled {
    color: #6c757d;
    pointer-events: none; }

.button-block {
  display: block;
  width: 100%; }
  .button-block + .button-block {
    margin-top: 0.5rem; }

input[type="submit"].button-block,
input[type="reset"].button-block,
input[type="button"].button-block {
  width: 100%; }

.link .link__icon + .link__text {
  margin-left: 0.5rem; }

.link-event .link__text {
  display: none; }

label.link-upload {
  margin-bottom: 0; }

.link-upload .link__upload,
.link-upload .link__text {
  display: none; }

label.button--upload {
  margin-bottom: 0; }

.button--upload .button__upload {
  display: none; }

.button-success {
  background-color: #ffbd00;
  color: #181717; }

.button-group {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
  border-radius: 0.25rem;
  border: none; }
  .button-group .button, .button-group .app-sidebar__button {
    position: relative;
    flex: 0 1 auto;
    box-shadow: none; }
  .button-group .button + .button, .button-group .app-sidebar__button + .button, .button-group .button + .app-sidebar__button, .button-group .app-sidebar__button + .app-sidebar__button {
    margin-left: -1px; }
  .button-group .button:not(:first-of-type), .button-group .app-sidebar__button:not(:first-of-type) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .button-group .button:not(:last-child), .button-group .app-sidebar__button:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }

.button-create {
  box-shadow: none; }

.button, .app-sidebar__button {
  font-size: 1em; }

.search-content__search {
  display: flex; }
  .search-content__search-field {
    flex-grow: 1;
    flex-shrink: 0; }

.search-content__list:not(:first-of-type) {
  margin-top: 1rem; }

.search-content__list .button__text {
  display: none; }

:root {
  --input-padding-x: 0.75rem;
  --input-padding-y: 0.75rem; }

.form-label-group {
  position: relative;
  margin-bottom: 1rem; }
  .form-label-group > input,
  .form-label-group > label {
    padding: var(--input-padding-y) var(--input-padding-x); }
  .form-label-group > input {
    height: auto; }
  .form-label-group > label {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    margin-bottom: 0;
    /* Override default `<label>` margin */
    line-height: 1.5;
    color: #495057;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    transition: all 0.1s ease-in-out;
    user-select: none; }
  .form-label-group input::placeholder {
    color: transparent; }
  .form-label-group input:not(:placeholder-shown) {
    padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
    padding-bottom: calc(var(--input-padding-y) / 3); }
  .form-label-group input:not(:placeholder-shown) ~ label {
    padding-top: calc(var(--input-padding-y) / 3);
    padding-bottom: calc(var(--input-padding-y) / 3);
    font-size: 12px;
    color: #777; }

.tree-container .tree-container {
  margin-left: 1rem; }

.image-crop {
  background: #4a4a4a; }

.image-crop__header {
  float: right;
  width: 15rem;
  padding: 0.75em;
  color: white; }

.image-crop__container {
  width: calc(100% - 15rem); }

.modal {
  margin: 3rem auto;
  background-color: #ffffff;
  max-width: 56rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15); }
  .modal-body--open {
    overflow: hidden; }
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    overflow-x: auto; }
  .modal-header {
    padding: 0 0.5rem;
    border-bottom: 1px solid #707070;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .5rem 1rem; }
    .modal-header .button, .modal-header .app-sidebar__button {
      box-shadow: none;
      padding: 0 .25rem; }
  .modal-header__headline {
    font-size: 1.2rem;
    margin-bottom: 0; }
  .modal-content {
    padding: 0rem 1rem 1rem; }
  .modal__footer {
    border-top: 1px solid #707070;
    border-bottom: 1px solid #707070; }

.ReactCrop {
  position: relative;
  display: inline-block;
  cursor: crosshair;
  overflow: hidden;
  max-width: 100%;
  background-color: #000; }
  .ReactCrop:focus {
    outline: none; }
  .ReactCrop--disabled, .ReactCrop--locked {
    cursor: inherit; }
  .ReactCrop__image {
    /* autoprefixer: off */
    display: block;
    max-width: 100%;
    max-height: -webkit-fill-available;
    max-height: -moz-available;
    max-height: fill-available;
    touch-action: manipulation; }
  .ReactCrop--crop-invisible .ReactCrop__image {
    opacity: 0.5; }
  .ReactCrop__crop-selection {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate3d(0, 0, 0);
    box-sizing: border-box;
    cursor: move;
    box-shadow: 0 0 0 9999em rgba(0, 0, 0, 0.5);
    touch-action: manipulation;
    border: 1px solid;
    border-image-source: url("data:image/gif;base64,R0lGODlhCgAKAJECAAAAAP///////wAAACH/C05FVFNDQVBFMi4wAwEAAAAh/wtYTVAgRGF0YVhNUDw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OEI5RDc5MTFDNkE2MTFFM0JCMDZEODI2QTI4MzJBOTIiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OEI5RDc5MTBDNkE2MTFFM0JCMDZEODI2QTI4MzJBOTIiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoTWFjaW50b3NoKSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuZGlkOjAyODAxMTc0MDcyMDY4MTE4MDgzQzNDMjA5MzREQ0ZDIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjAyODAxMTc0MDcyMDY4MTE4MDgzQzNDMjA5MzREQ0ZDIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+Af/+/fz7+vn49/b19PPy8fDv7u3s6+rp6Ofm5eTj4uHg397d3Nva2djX1tXU09LR0M/OzczLysnIx8bFxMPCwcC/vr28u7q5uLe2tbSzsrGwr66trKuqqainpqWko6KhoJ+enZybmpmYl5aVlJOSkZCPjo2Mi4qJiIeGhYSDgoGAf359fHt6eXh3dnV0c3JxcG9ubWxramloZ2ZlZGNiYWBfXl1cW1pZWFdWVVRTUlFQT05NTEtKSUhHRkVEQ0JBQD8+PTw7Ojk4NzY1NDMyMTAvLi0sKyopKCcmJSQjIiEgHx4dHBsaGRgXFhUUExIREA8ODQwLCgkIBwYFBAMCAQAAIfkEBQoAAgAsAAAAAAoACgAAAhWEERkn7W3ei7KlagMWF/dKgYeyGAUAIfkEBQoAAgAsAAAAAAoACgAAAg+UYwLJ7RnQm7QmsCyVKhUAIfkEBQoAAgAsAAAAAAoACgAAAhCUYgLJHdiinNSAVfOEKoUCACH5BAUKAAIALAAAAAAKAAoAAAIRVISAdusPo3RAzYtjaMIaUQAAIfkEBQoAAgAsAAAAAAoACgAAAg+MDiem7Q8bSLFaG5il6xQAIfkEBQoAAgAsAAAAAAoACgAAAg+UYRLJ7QnQm7SmsCyVKhUAIfkEBQoAAgAsAAAAAAoACgAAAhCUYBLJDdiinNSEVfOEKoECACH5BAUKAAIALAAAAAAKAAoAAAIRFISBdusPo3RBzYsjaMIaUQAAOw==");
    border-image-slice: 1;
    border-image-repeat: repeat; }
    .ReactCrop--disabled .ReactCrop__crop-selection {
      cursor: inherit; }
  .ReactCrop__drag-handle {
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.7);
    box-sizing: border-box;
    outline: 1px solid transparent; }
  .ReactCrop .ord-nw {
    top: 0;
    left: 0;
    margin-top: -5px;
    margin-left: -5px;
    cursor: nw-resize; }
  .ReactCrop .ord-n {
    top: 0;
    left: 50%;
    margin-top: -5px;
    margin-left: -5px;
    cursor: n-resize; }
  .ReactCrop .ord-ne {
    top: 0;
    right: 0;
    margin-top: -5px;
    margin-right: -5px;
    cursor: ne-resize; }
  .ReactCrop .ord-e {
    top: 50%;
    right: 0;
    margin-top: -5px;
    margin-right: -5px;
    cursor: e-resize; }
  .ReactCrop .ord-se {
    bottom: 0;
    right: 0;
    margin-bottom: -5px;
    margin-right: -5px;
    cursor: se-resize; }
  .ReactCrop .ord-s {
    bottom: 0;
    left: 50%;
    margin-bottom: -5px;
    margin-left: -5px;
    cursor: s-resize; }
  .ReactCrop .ord-sw {
    bottom: 0;
    left: 0;
    margin-bottom: -5px;
    margin-left: -5px;
    cursor: sw-resize; }
  .ReactCrop .ord-w {
    top: 50%;
    left: 0;
    margin-top: -5px;
    margin-left: -5px;
    cursor: w-resize; }
  .ReactCrop__disabled .ReactCrop__drag-handle {
    cursor: inherit; }
  .ReactCrop__drag-bar {
    position: absolute; }
    .ReactCrop__drag-bar.ord-n {
      top: 0;
      left: 0;
      width: 100%;
      height: 6px;
      margin-top: -3px; }
    .ReactCrop__drag-bar.ord-e {
      right: 0;
      top: 0;
      width: 6px;
      height: 100%;
      margin-right: -3px; }
    .ReactCrop__drag-bar.ord-s {
      bottom: 0;
      left: 0;
      width: 100%;
      height: 6px;
      margin-bottom: -3px; }
    .ReactCrop__drag-bar.ord-w {
      top: 0;
      left: 0;
      width: 6px;
      height: 100%;
      margin-left: -3px; }
  .ReactCrop--new-crop .ReactCrop__drag-bar,
  .ReactCrop--new-crop .ReactCrop__drag-handle,
  .ReactCrop--fixed-aspect .ReactCrop__drag-bar {
    display: none; }
  .ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-n,
  .ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-e,
  .ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-s,
  .ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-w {
    display: none; }
  @media (pointer: coarse) {
    .ReactCrop__drag-handle {
      width: 20px;
      height: 20px; }
    .ReactCrop .ord-nw {
      margin-top: -10px;
      margin-left: -10px; }
    .ReactCrop .ord-n {
      margin-top: -10px;
      margin-left: -10px; }
    .ReactCrop .ord-ne {
      margin-top: -10px;
      margin-right: -10px; }
    .ReactCrop .ord-e {
      margin-top: -10px;
      margin-right: -10px; }
    .ReactCrop .ord-se {
      margin-bottom: -10px;
      margin-right: -10px; }
    .ReactCrop .ord-s {
      margin-bottom: -10px;
      margin-left: -10px; }
    .ReactCrop .ord-sw {
      margin-bottom: -10px;
      margin-left: -10px; }
    .ReactCrop .ord-w {
      margin-top: -10px;
      margin-left: -10px; }
    .ReactCrop__drag-bar.ord-n {
      height: 16px;
      margin-top: -8px; }
    .ReactCrop__drag-bar.ord-e {
      width: 16px;
      margin-right: -8px; }
    .ReactCrop__drag-bar.ord-s {
      height: 16px;
      margin-bottom: -8px; }
    .ReactCrop__drag-bar.ord-w {
      width: 16px;
      margin-left: -8px; } }

.app {
  max-height: 100vh;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #f2f2f2;
  color: #858585; }
  .app a {
    text-decoration: none; }
  .app-body {
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    min-width: 0;
    justify-content: flex-start;
    align-items: stretch;
    align-content: flex-end; }
  .app-content {
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: auto;
    min-width: 0; }
    .app-content > :only-child {
      width: 100%;
      flex-grow: 1;
      overflow-y: auto;
      max-height: calc(100vh - 3.5rem); }

.app-sidebar {
  display: flex;
  background-color: #f8f8f8;
  color: #848484;
  flex-grow: 0;
  flex-shrink: 1; }
  .app-sidebar__button {
    box-shadow: none; }
  .app-sidebar--closed .app-sidebar__content {
    transition: width 0.5s ease-out;
    width: 0rem; }
  .app-sidebar__controls {
    order: 1; }
    .app-sidebar__controls .button__text,
    .app-sidebar__controls .link__text {
      display: none; }
  .app-sidebar__content {
    transition: width 0.5s ease-out;
    width: 10rem;
    overflow: hidden;
    order: 0; }
  .app-sidebar__content.nav {
    padding-top: 2rem;
    flex-direction: column; }
    .app-sidebar__content.nav .nav__item {
      position: relative; }
      .app-sidebar__content.nav .nav__item.active {
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25); }
        .app-sidebar__content.nav .nav__item.active::after {
          position: absolute;
          right: 0;
          bottom: 50%;
          display: block;
          transform: translateY(50%);
          content: "";
          width: 0;
          height: 0;
          border-top: 0.5rem solid transparent;
          border-bottom: 0.5rem solid transparent;
          border-right: 0.5rem solid #848484; }

.app-header {
  background-color: #ffbd00;
  color: #ffffff;
  padding: 0.25rem 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .app-header a {
    text-decoration: none; }
  .app-header .app-logo {
    position: relative;
    color: #ffffff;
    padding: 0.25rem 0; }
    .app-header .app-logo::after {
      content: "";
      position: absolute;
      left: 1rem;
      right: 1rem;
      bottom: 0.25rem;
      height: 1px;
      background-color: #ffffff; }
  .app-header .app-user {
    float: right; }

.overview {
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem; }
  .overview__header {
    border-bottom: 1px solid #707070; }
    .overview__header .header__headline {
      font-size: 18px;
      margin-bottom: 0.2em; }
    .overview__header + .overview_list,
    .overview__header + .overview__info-box {
      margin-top: 1rem; }
  .overview .overview_list .list-item:nth-of-type(odd) {
    background-color: #e0e0e0; }
  .overview__footer {
    display: flex;
    justify-content: center;
    margin-top: 1rem; }
  .overview__info-box {
    background-color: #ebf3fb;
    padding: 1rem; }

.options__text {
  display: none; }

.app-content.content--signin {
  width: 50%;
  min-width: 20rem;
  margin-left: auto;
  margin-right: auto; }

.content--signin {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px; }

.form--signin {
  width: 100%;
  max-width: 420px;
  padding: 15px;
  margin: 0 auto;
  background: #fff;
  border-radius: 2px; }
  .form--signin .button-success {
    float: right; }

.app-content.dashboard {
  grid-column-start: content-start;
  grid-column-end: rside-end; }

.dashboard {
  padding: 1rem; }

.placeholder {
  background-color: #FFA716;
  display: flex;
  justify-content: center; }

.placeholder--logo {
  width: 10rem;
  height: 3rem; }

.placeholder--background {
  width: 12rem;
  height: 16rem;
  margin-right: 1rem; }

.placeholder--preview {
  width: 12rem;
  height: 16rem;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-around; }

.placeholder--bannerBackground {
  width: 47rem;
  height: 13rem; }

.profile-card {
  display: flex;
  font-size: 1rem; }
  .profile-card__user-fields {
    font-size: .8rem; }
  .profile-card__actions {
    display: flex;
    flex-direction: row-reverse; }
    .profile-card__actions .button--logout {
      align-items: center;
      background-color: transparent;
      border: none;
      color: white;
      display: flex;
      justify-items: flex-end;
      padding: 0 0.75rem 0 0;
      box-shadow: none; }
      .profile-card__actions .button--logout .button__text,
      .profile-card__actions .button--logout .link__text {
        order: 0;
        font-size: .75rem; }
      .profile-card__actions .button--logout .button__icon,
      .profile-card__actions .button--logout .link__icon {
        order: 1;
        margin-left: 0.5rem;
        height: 0.8rem; }
  .profile-card__picture {
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    color: #ffbd00;
    border-radius: 50%; }
  .profile-card__picture-icon {
    width: 1.5rem;
    height: 1.5rem; }

.portal-sidebar {
  padding: 0 0.5rem;
  max-width: 15rem;
  background-color: #7c7c7c;
  color: white;
  flex-shrink: 0; }
  .portal-sidebar__header {
    padding: 0 0.25rem;
    border-bottom: 1px solid currentColor; }
  .portal-sidebar__controls {
    margin-top: 0.25rem; }
  .portal-sidebar__header-headline {
    font-size: 1.25rem;
    margin-bottom: 0; }
  .portal-sidebar__content {
    margin: .5rem 0 1.5rem; }

.page-tree__item {
  line-height: 1rem; }

.page-tree__item-icon {
  margin-right: 0.5rem;
  font-size: 1rem; }

.page-tree .link.link-settings {
  float: right; }
  .page-tree .link.link-settings .link__text {
    display: none; }

.portal-content,
.portal-sidebar {
  flex-grow: 1;
  overflow-y: auto;
  max-height: calc(100vh - 3rem); }

.tree-branch {
  position: relative;
  margin: 0.5rem 0 0.5rem;
  list-style: none;
  padding-left: 0; }
  .tree-branch > .tree-branch {
    margin: 0 0 0 2rem; }
    .tree-branch > .tree-branch::after {
      position: absolute;
      content: "";
      left: -1rem;
      top: 0.2rem;
      bottom: 0.2rem;
      width: 1px;
      background-color: #c1c9d0; }
  .tree-branch__leave {
    border-top: 1px solid #c1c9d0;
    padding: 0.25rem 0.5rem;
    display: flex;
    justify-content: space-between; }
  .tree-branch__item-text {
    display: flex;
    align-items: center; }
  .tree-branch__leave--to-update {
    background-color: rgba(255, 189, 0, 0.25); }

.page-sidebar {
  padding: 0 0.5rem;
  width: 15rem;
  background-color: #7c7c7c;
  color: white;
  flex-shrink: 0; }
  .page-sidebar__header {
    padding: 0 0.25rem;
    border-bottom: 1px solid currentColor; }
  .page-sidebar__controls {
    margin-top: 0.25rem; }
  .page-sidebar__header-headline {
    font-size: 1.25rem;
    margin-bottom: 0; }
  .page-sidebar__content {
    margin-top: 0.5rem; }

.content-column {
  background-color: #f8f8f8;
  border-radius: 3px;
  border: 1px solid #c3c3c3;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  margin-bottom: 2.5em;
  margin: 0 auto;
  max-width: 732px;
  padding: 1rem; }
  .content-column + .content-column {
    margin-top: 1rem; }
  .content-column__header {
    padding: 0;
    border-bottom: 1px solid #c3c3c3;
    margin-bottom: 1em; }
    .content-column__header h2 {
      font-size: 0.9rem; }
  .content-column__header + .content-column__footer {
    border-top-color: transparent; }
  .content-column__footer {
    border-top: 1px solid #c3c3c3;
    border-bottom: 1px solid #c3c3c3;
    text-align: center;
    margin: 0.75em 0 0.5rem 0; }

.page-content {
  flex-grow: 1;
  overflow-y: auto;
  max-height: calc(100vh - 3.5rem); }

.page-tree__list {
  position: relative;
  margin: 0.5rem 0 0.5rem;
  list-style: none;
  padding-left: 1.25rem; }
  .page-tree__list::after {
    position: absolute;
    content: "";
    width: 1px;
    background-color: #ffffff;
    top: 0;
    bottom: 0;
    left: 0.5rem; }

.content--contents {
  padding: 1rem 1rem 0; }

.content-edit .quill {
  background-color: #ffffff; }

.content-element {
  display: flex;
  flex-direction: column; }
  .content-element__header {
    align-items: center;
    background-color: #cbc9c9;
    color: #fff;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    padding: 0rem 0.5rem; }
    .content-element__header .button, .content-element__header .app-sidebar__button {
      color: currentColor;
      font-size: 14px; }
    .content-element__header .button__text {
      display: none; }
    .content-element__header-title {
      font-size: 14px; }
  .content-element__content {
    font-size: 14px;
    padding: 0.75rem;
    margin-bottom: 0.5rem;
    background-color: white;
    border: 1px solid #cecece; }
  .content-element__footer {
    border: 1px solid transparent;
    border-top-color: #c3c3c3;
    border-bottom-color: #c3c3c3;
    padding: 0.25rem;
    text-align: center;
    margin: 0.75em 0 0.5rem 0; }
  .content-element + .content-element {
    margin-top: 1rem; }

.content-type__body {
  display: flex;
  justify-content: space-between;
  padding: 0 1rem 1rem; }

.content-type__nav {
  background-color: #ebebeb;
  margin-bottom: 0.5rem; }

.content-type__item {
  display: flex;
  align-items: center;
  border: 1px solid #dedede;
  background-color: #efefef;
  padding: 0.25rem 0.75rem; }

.content-type__item-image {
  height: calc(6rem * (9 / 16));
  width: 6rem;
  border: 1px solid #707070;
  background-color: #c3c3c3; }

.content-type__item-info {
  margin-left: 0.5rem; }

.file {
  display: flex;
  flex-direction: row; }
  .file__preview {
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    flex-grow: 1;
    align-items: center;
    justify-items: stretch; }
  .file-image {
    display: flex;
    width: 45px;
    height: 45px;
    align-items: center;
    justify-content: center; }
  .file-image__img {
    display: block;
    max-width: 100%;
    max-height: 100%; }
  .file-name + .file-image,
  .file-name + .file-name__label,
  .file-image + .file-name,
  .file-image + .file-name__label,
  .file-name__label + .file-image,
  .file-name__label + .file-name {
    margin-left: 0.5rem; }

.file__control .button__text {
  display: none; }
